import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Navigation from './parts/Navigation';

import Hero from './parts/Hero';
import Features from './parts/Features';
import Pricing from './parts/Pricing';

import Contact from './parts/Contact';
import Footer from './parts/Footer';

import Download from './parts/Download';
import Videos from './parts/Videos';
import Privacy from './parts/Privacy';

import Loader from './components/loader'

export class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            loadingText: "Iniciando...",
            data: null,
        };
    }

    componentDidMount() {
        global.cmsApi.getLandingCms((data) => {
            try {
                var homeData = data.findLandingContent.flatData;
                this.setState({
                    data: homeData
                }, () => {
                    this.setLoading(false);
                });
            } catch (error) {
                //loadDefaultImages();
            }
        });
    }

    setLoading(isLoading, loadingText) {
        this.setState({ isLoading: isLoading, loadingText: loadingText });
    }

    render() {
        return (
            <>
                {
                    (this.state.isLoading ?? true)
                        ? <div className="h-screen">
                            <Loader text={this.state.loadingText} />
                        </div>
                        : (this.state.data.planesSecciones && this.state.data.videos)
                            ? <Router>
                                <div>
                                    <Navigation data={null} />
                                    <Switch>
                                        <Route path="/Download">
                                            <div>
                                                <Download />
                                                <Contact />
                                            </div>
                                        </Route>
                                        <Route path="/Videos">
                                            <div>
                                                <Videos data={this.state.data.videos} />
                                                <Contact />
                                            </div>
                                        </Route>
                                        <Route path="/Privacy">
                                            <div>
                                                <Privacy />
                                                <Contact />
                                            </div>
                                        </Route>
                                        <Route path="/">
                                            <div>
                                                <Hero></Hero>
                                                <Features></Features>
                                                <Pricing data={this.state.data.planesSecciones}></Pricing>
                                                <Contact />
                                            </div>
                                        </Route>
                                    </Switch>
                                    <Footer />
                                </div>
                            </Router>
                            : null
                }
            </>
        )
    }
}

export default Home;