import React, { Component } from "react";
import logoSamit from '../LogoSamitFlat.svg';

export class Navigation extends Component {

    openInNewTab(url) {
        var win = window.open(url, '_blank');
        win.focus();
    }

    handleClick = (e, hash) => {
        e.preventDefault();
        console.log(hash);
        if (window.location.pathname !== "/") {
            window.location.href = window.location.origin + hash;
        }
    };

    render() {
        return (
            <header id="topnav" className="defaultscroll sticky">
                <div className="container">
                    <div>
                        <a className="logo page-scroll" href="#home">
                            <img src={logoSamit} height={45} alt="SAMIT Logo" style={{ marginTop: -5 }} />
                            <span style={{ paddingLeft: 4, fontWeight: 'bold' }}>SAMITsql</span>
                        </a>
                    </div>
                    <div className="buy-button">
                        <a href="https://web.samitcloud.com" target="_blank" rel="noreferrer" className="btn btn-primary page-scroll uppercase">Ingresar Web</a>
                    </div>
                    <div className="menu-extras">
                        <div className="menu-item">
                            <div className="navbar-toggle">
                                <div className="lines">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="navigation">
                        <ul className="navigation-menu">
                            <li><a className="page-scroll" href="#home">Inicio</a></li>
                            <li><a className="page-scroll" href="#features">Características</a></li>
                            <li><a className="page-scroll" href="#pricing">Precios</a></li>
                            <li>
                                <a href={"/Videos"} className="align-items-center">
                                    <span className="p-0">
                                        {"🖥️ Tutoriales"}
                                    </span>
                                </a>
                            </li>
                            <li className="has-submenu">
                                <a href="/none" onClick={(e) => { e.preventDefault(); }} className="noClose">
                                    Mas
                                    <i className="mdi mdi-chevron-right"></i>
                                </a>
                                <ul className="submenu">
                                    <li>
                                        <a href={"https://samitsql.github.io/SamitDocs"} target="_blank" rel="noreferrer" style={{ fontSize: 14, textTransform: 'none' }}>
                                            <i className="mdi mdi-update" style={{ fontSize: 15, marginRight: 4 }}></i>
                                            {"Historial de Actualizaciones"}
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"/Download"} style={{ fontSize: 14, textTransform: 'none' }}>
                                            <i className="mdi mdi-download" style={{ fontSize: 15, marginRight: 4 }}></i>
                                            {"Zona de Descargas"}
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"/Privacy"} style={{ fontSize: 14, textTransform: 'none' }}>
                                            <i className="mdi mdi-file" style={{ fontSize: 15, marginRight: 4 }}></i>
                                            {"Política de Privacidad"}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        );
    }
}

export default Navigation;
