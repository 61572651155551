import { queryCMS } from './cmsapi.js'

global.cmsApi = {

    getCmsItem: function (code) {
        var items = global.cmsDefaultData.filter(item => item.code === code);
        if (items.length > 0) {
            return items[0];
        } else {
            return null;
        }
    },

    getCmsData: function (code, completion) {
        var cmsItem = this.getCmsItem(code)
        if (cmsItem === null) return;
        var local = localStorage.getItem(code);
        if (local) {
            var localData = JSON.parse(local);
            completion(localData);
            console.log("Local")
        }
        queryCMS(cmsItem.query).then((data) => {
            try {
                completion(data);
                console.log("Cloud")
                localStorage.setItem(code, JSON.stringify(data));
            } catch (error) {
                //loadDefaultImages();
            }
        }, (error) => {
            //loadDefaultImages();
        });
    },

    getLandingCms: function (completion) {
        var code = "CmsLanding"
        this.getCmsData(code, completion);
    },

}

global.cmsDefaultData = [
    {
        code: "CmsLanding",
        query:
            `
              findLandingContent(id: "e55588bf-68cf-49c3-a379-f3a7fd172980") {
    flatData {
      planesSecciones {
        flatData {
          titulo
          subtitulo
          planes {
            flatData {
              nombre              
              precioAnual
              caracteristicas {
                titulo
              }
              color
              destacado
            }
          }
        }
      }
      videos {
        flatData {
          titulo
          descripcion
          modulo
          url
          versionApp
        }
      }
    }
  }
        `,
        data: null
    },
]