import React, { Component } from "react";

export class Pricing extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div id="pricing">
                <section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 text-center">
                                <div className="section-title mb-4 pb-2 mt-lg-100 mt-md-100">
                                    <h4 className="title mb-4">Planes de SamitCloud</h4>
                                    <p className="text-muted para-desc mb-0 mx-auto">
                                        SamitCloud es la forma mas facil de empezar a trabajar, usando nuestros servidores en <span className="text-primary font-weight-bold">la nube</span> para que no te preocupes por la implementacion.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {
                            this.props.data.map((item, index) => {
                                var seccion = item.flatData;
                                return (
                                    <div>
                                        <div className="section-title pt-4 text-center">
                                            <h4 className="title text-uppercase" style={{ fontWeight: 'bold' }} >
                                                {seccion.titulo}
                                            </h4>
                                            <p className="text-muted pb-2 mx-auto">
                                                {seccion.subtitulo}
                                            </p>
                                        </div>
                                        <div className="row align-items-start justify-content-center">

                                            {
                                                seccion.planes.map((item, index) => {
                                                    var plan = item.flatData;
                                                    return (
                                                        <div className="col-lg-4 col-md-6 col-12 mb-5" style={{ padding: 5 }} key={index}>
                                                            <div className="pricing-rates business-rate rounded" style={{ border: '2px solid ' + ((plan.destacado) ? plan.color : '#e4e9f6') }}>
                                                                <div className="card-body py-4 px-4">
                                                                    {
                                                                        (plan.destacado)
                                                                            ? <div className="top-note-wrapper">
                                                                                <span className="top-note h5" style={{ backgroundColor: plan.color, color: 'white', fontSize: 16, fontWeight: '700' }}>
                                                                                    Más popular 🔥
                                                                                </span>
                                                                            </div>
                                                                            : null
                                                                    }

                                                                    <h2 className="title text-uppercase mb-4 mt-2" style={{ color: plan.color, fontWeight: '700' }}>
                                                                        {
                                                                            plan.nombre
                                                                        }
                                                                    </h2>

                                                                    {
                                                                        <>
                                                                            <div className="d-flex mt-5" style={{ color: 'black' }}>
                                                                                <span className="h4 mb-0 mt-3">
                                                                                    $
                                                                                </span>
                                                                                <span className="price h1 mb-0" style={{ fontWeight: 'bold', fontSize: 58 }}>
                                                                                    {
                                                                                        new Intl.NumberFormat("es-CO").format(plan.precioAnual)
                                                                                    }
                                                                                </span>
                                                                                <span className="h5 align-self-end mb-3">
                                                                                    /año*
                                                                                </span>
                                                                            </div>
                                                                            <div className="d-flex mb-5" style={{ color: '#4a4a4a', marginLeft: 14 }}>
                                                                                <span className="h5 mb-0">
                                                                                    Valor diario: $
                                                                                    {
                                                                                        new Intl.NumberFormat("es-CO", { maximumFractionDigits: 0 }).format(plan.precioAnual / 365)
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </>
                                                                        // :
                                                                        // <>
                                                                        //     <div className="h5" style={{ margin: '87px 0px' }}>
                                                                        //         <strong>
                                                                        //             ¿Necesitas algo sin restricciones?
                                                                        //         </strong>
                                                                        //         <br></br>
                                                                        //         <span>
                                                                        //             Este paquete es para ti.
                                                                        //         </span>
                                                                        //     </div>
                                                                        // </>
                                                                    }

                                                                    <ul className="list-unstyled mb-0 pl-0">
                                                                        {
                                                                            plan.caracteristicas.map((feature, indexFeature) => (
                                                                                <li className="h6 mb-0 d-flex flex-row" key={indexFeature}>
                                                                                    <div className="text-primary h5 mr-2">
                                                                                        <i className="mdi mdi-check" style={{ color: plan.color }}>
                                                                                        </i>
                                                                                    </div>
                                                                                    <div className="mt-1" style={{ lineHeight: 1.3, fontWeight: '700' }}>
                                                                                        {
                                                                                            feature.titulo
                                                                                        }
                                                                                    </div>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                    <div className="d-flex" style={{ color: '#4a4a4a', fontSize: 14 }}>
                                                                        <span className="mb-0">
                                                                            *El pago se realiza por el valor total del año
                                                                        </span>
                                                                    </div>
                                                                    <a href="#contact" className="btn boton mt-4 btn-block text-uppercase h5"
                                                                        style={{ backgroundColor: plan.color, color: plan.color }}
                                                                    >
                                                                        <span style={{ color: 'white', fontSize: 18, fontWeight: '700' }}>
                                                                            {
                                                                                (plan.precioMes > 0)
                                                                                    ? "Elegir Plan"
                                                                                    : "Contactanos"
                                                                            }
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                );
                            })
                        }



                    </div>
                </section>
            </div>
        );
    }
}

export default Pricing;
